.homePage {
    width: 100%;

    .search {
        padding: 8px 16px;
    }

    .homeHeader {
        padding: 8px 16px;
        position: sticky;
        top: 56px;
        width: 100%;
        background-color: #0a0a0a;
        z-index: 13;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;

        .tabBar {
            margin: 10px auto;
        }

        .searchInput {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 10px 16px;
            border-radius: 8px;
            border: 1px solid #404040;
            background-color: #171717;
            box-sizing: border-box;
            flex: 1;
            margin: 8px 16px;
            width: 100%;

            input {
                width: 100%;
                padding: 0;
                background-color: #171717;
                color: #d4d4d4;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                border: none;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    .homeContent {
        margin-top: 10px;
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
}